<script>
import swal from 'sweetalert2'

export default {
  data () {
    return {
      errors: {}
    }
  },
  methods: {
    setErrors (errors) {
      if (errors || errors === {}) {
        this.errors = errors
      }
    },
    resetErrors () {
      this.setErrors({})
    },
    error (key) {
      return this.errors[key] || null
    },
    hasError (key) {
      return this.error(key) ? true : false
    },
    type (key) {
      return this.error(key) ? 'is-danger' : ''
    },
    handleError (err) {
      if (err.response && err.response.data) {
        this.setErrors(err.response.data.errors)
        swal('Oops!', err.response.data.message || 'An error occurred', 'error')
      }
    }
  }
}
</script>
